@import '~bootstrap/dist/css/bootstrap.min.css';

app-root,
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--color-plot-01);
    // scrollbar-width: thin !important;
    // scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-background-color);
}
/* width */
::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: white;
    box-shadow: inset 0 0 5px var(--scrollbar-background-color);
    border-radius: 15px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color); 
    border-radius: 15px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-color); 
  }

.ng-select.select-drpDown .ng-dropdown-panel {
    box-sizing: border-box;
    opacity: 0;
    position: absolute;
    width: 93%;
    z-index: 1050;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    margin-left: 9px;
    margin-top: 0px;
    max-height: 160px;
    overflow-y: auto;
}

///custom truedata font color and style standards
.trueData-blue {
    background-color: #3d7fd6;
    opacity: 1;
}

.fontStyle {
    color: #1e1e57;
    font-family: 'Robert';
    font-weight: 200;

    .sidenav-container .mat-drawer {
        transition: width 0.3s ease;
    }
}

/// custom mat-expansion-panel
.mat-expansion-panel.mat-expanded {
    display: flex !important;
    flex-direction: column !important;
}

.mat-expansion-panel-content {
    flex: 1 !important;
}

.mat-expansion-panel-body {
    width: 100% !important;
    height: 100% !important;
}

.btn-style-01 {
    $btn-color: #3d7fd6;
    $btn-hover-color: #436acdb1;
    position: relative;
    background-color: $btn-color;
    border-color: $btn-color;
    color: #fff;
    overflow: hidden;
    &::before,
    &::after {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100px) skewX(-15deg);
        content: '';
    }
    &::before {
        width: 60px;
        background: rgba(255, 255, 255, 0.5);
        filter: blur(30px);
        opacity: 0.5;
    }
    &::after {
        width: 30px;
        left: 30px;
        background: rgba(255, 255, 255, 0.2);
        filter: blur(5px);
    }
    &:hover {
        background-color: $btn-hover-color;
        border-color: $btn-hover-color;
        &::before {
            opacity: 1;
        }
        &::before,
        &::after {
            transform: translateX(300px) skewX(-15deg);
            transition: all 0.9s ease;
        }
    }
}

// $color-duskblue: #2E5299;
// $color-windowsblue: #3D7FD6;
// $color-cornflowerblue: #6196DD;
// $color-deepbluesea: #00517F;
// $color-columbiablue: #99DAFF;
// $color-frenchpass: #CCECFF;
// $color-seashell: #F0F3F4;
// $color-dustyorange: #F08D48;
// $color-bluezodiac: #1E1E57;
// $color-blackpearl: #050E1F;
// $color-fountainblue: #56AEA7;
// $color-glacier: #71BBB6;
// $color-shadowgreen: #88C6C2;

.light-theme {
    --color-plot-01: #fff;
    --color-plot-02: #050e1f; //columbiablue
    --color-plot-03: #ccecff; //frenchpass
    --color-plot-04: #6196dd; //cornflowerblue
    --color-plot-05: #71bbb6; //glacier
    --color-plot-06: #56aea7; //fountainblue
    --color-plot-07: #88c6c2; //shadowgreen
    --color-plot-08: #3d7fd6; //windowsblue
    --color-plot-09: #2e5299; //duskblue
    --color-plot-10: #00517f; //deepbluesea
    --color-plot-11: #3d7fd6; //bluezodiac
    --color-plot-12: #050e1f; //blackpearl
    --color-plot-13: #f08d48; //dustyorange
    --color-plot-14: #050e1f;
    --color-plot-15: #ccd9f187;
    --color-plot-16: #050e1f;
    --color-plot-17: #1e1e57;
    --color-plot-18: #1e1e57;

    --data-explorer-var-01: 0px;

    --data-explorer-color-plot-01: #1e1e57;

    --action-row-color-plot-01: #c5d9f2;
    --action-row-color-plot-02: #000;
    --action-row-color-plot-03: #fff;
    --action-row-color-plot-04: #00000058;
    --action-row-color-plot-05: #ffffff5d;

    --grid-color-plot-01: #000;
    --grid-color-plot-02: #bdecf4;
    --grid-color-plot-03: #fff;
    --grid-color-plot-04: #3d7fd6;
    --grid-color-plot-05: #fff;
    --grid-color-plot-06: #00000088;
    --grid-color-plot-07: #c6c5c5d6;
    --grid-color-plot-08: #000;
    --grid-color-plot-09: #2195f333;
    --grid-color-plot-10: #2f88d165;
    --grid-color-plot-11: #2195f379;
    --grid-color-plot-12: #000;
    --grid-color-plot-13: rgba(183, 179, 179, 0.174);
    --grid-color-plot-14: #3d7fd6;

    --scrollbar-background-color: #cfd8dc;
    --scrollbar-thumb-color: #3d7fd6;
}

.dark-theme {
    --color-plot-01: #050e1f; //blackpearl
    --color-plot-02: #99daff; //columbiablue
    --color-plot-03: #f0f3f4; //seashell
    --color-plot-04: #6196dd; //cornflowerblue
    --color-plot-05: #71bbb6; //glacier
    --color-plot-06: #56aea7; //fountainblue
    --color-plot-07: #88c6c2; //shadowgreen
    --color-plot-08: #3d7fd6; //windowsblue
    --color-plot-09: #2e5299; //duskblue
    --color-plot-10: #00517f; //deepbluesea
    --color-plot-11: #1e1e57; //bluezodiac
    --color-plot-12: #ccecff; //frenchpass
    --color-plot-13: #f08d48; //dustyorange
    --color-plot-14: #f0f3f4;
    --color-plot-15: #3d7fd6;
    --color-plot-16: #00000000;
    --color-plot-17: #1e1e57;
    --color-plot-18: #3d7fd6;

    --data-explorer-var-01: 0px;

    --data-explorer-color-plot-01: #19214a;

    --action-row-color-plot-01: #19214a;
    --action-row-color-plot-02: #fff;
    --action-row-color-plot-03: #3d7fd6;
    --action-row-color-plot-04: #ffffff7e;
    --action-row-color-plot-05: #3d7fd669;

    --grid-color-plot-01: #000;
    --grid-color-plot-02: #bdecf4;
    --grid-color-plot-03: #fff;
    --grid-color-plot-04: #3d7fd6;
    --grid-color-plot-05: #fff;
    --grid-color-plot-06: #00000088;
    --grid-color-plot-07: #c6c5c5d6;
    --grid-color-plot-08: #000;
    --grid-color-plot-09: #2195f333;
    --grid-color-plot-10: #2f88d165;
    --grid-color-plot-11: #2195f379;
    --grid-color-plot-12: #000;
    --grid-color-plot-13: rgba(183, 179, 179, 0.174);
    --grid-color-plot-14: #3d7fd6;

    --scrollbar-background-color: #cfd8dc;
    --scrollbar-thumb-color: #1e1e57;
}

.mat-select-value {
    color: #00517f !important;
}

.mat-form-field-label {
    color: #00517f !important;
}
